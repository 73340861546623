import React, { Suspense } from 'react' // , { useState }
import { Link } from "react-router-dom";

const Wallet = React.lazy(() => import('./Wallet'));

function toTitleCase(str) {
  return str.replace(
    /\w\S*/g,
    function(txt) {
      return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
    }
  );
}

const Nav = (props) => {

  let { accountAddress, setAccountAddress, tokens, setTokens, colors } = props

  // let links = colors.reduce((acc, color) => {
  //   acc[color.toUpperCase()] = "/" + color
  //   return acc
  // }, {})


  return <div className="bg-black container py-2 text-white text-sm select-none mx-auto">
    <nav className="">
      <div className="px-4">
        <div className="flex">
          <div className="flex-none w-16 h-16 ...">


            <Link to={{ pathname: "/" }} className="flex items-center py-0 px-2">
              <img alt="logo" src="https://info.co-operate.io/hubfs/co-op-logowithoutword.svg" className="w-8 h-8" width={24} height={24}></img>
            </Link>
          </div>

          <div className="flex-grow h-16 ">
            <div className="flex-grow">
              {!accountAddress && <Suspense fallback={<></>}>
                <Wallet setAccountAddress={setAccountAddress} setTokens={setTokens} className="bg-green hover:bg-green-600 px-6 py-2 rounded " />
              </Suspense>}
            </div>
          </div>

          <div className="flex-none w-16 h-16 ...">



          </div>

        </div>
      </div>



      <div className="flex flex-col justify-center items-center">
        <div className="select-none flex">

          {colors.map(color => {
            let textColor = tokens.includes(toTitleCase(color)) ? `text-${color} text-${color}-400` : 'text-locked'
            // console.log({tokens, color})
            return <Link key={color} to={{ pathname: "/" + color }} className={`py-4 px-2 ${textColor} font-semibold`}>{toTitleCase(color)}</Link>
          })}

        </div>
      </div>
    </nav>
  </div>
}

export default Nav