const Loading = function (props) {
  let { empty, full } = props

  return empty ? <div>

  </div> : <div>
    {/* {JSON.stringify(props)} */}
    {/* <div className="mt-5 border border-red-300 shadow rounded-md p-4 max-w-sm w-full mx-auto">
      <div className="animate-pulse flex space-x-4">
        <div className="rounded-full bg-red-400 h-12 w-12"></div>
        <div className="flex-1 space-y-4 py-1">
          <div className="h-4 bg-red-400 rounded w-3/4"></div>
          <div className="space-y-2">
            <div className="h-4 bg-red-400 rounded"></div>
            <div className="h-4 bg-red-400 rounded w-5/6"></div>
          </div>
        </div>
      </div>
    </div> */}
    <div className={`flex items-center justify-center ${full ? 'min-h-screen' : ''}`}>
      <div className="w-40 h-40 border-t-4 border-red-600 rounded-full animate-spin"></div>
    </div>
  </div>
}

export default Loading