import React, { Suspense, useState } from 'react' // , useEffect
import { FaCopy } from "react-icons/fa" // , FaUnlock
import Loading from "./Loading"

import {
  BrowserRouter as Router,
  Switch,
  Route,
} from "react-router-dom";

import Nav from './Nav'

import './App.css';

const Home = React.lazy(() => import('./Home'));
const Color = React.lazy(() => import('./Color'));

const colors = ["yellow", "red", "blue", "green"]

function App() {
  let [tokens, setTokens] = useState([])
  let [accountAddress, setAccountAddress] = useState()

  return (
    <Router>
      <div className="bg-black">
        <Nav tokens={tokens} setTokens={setTokens} accountAddress={accountAddress} setAccountAddress={setAccountAddress} colors={colors} />
        <Switch>

          {colors.map(c => <Route key={c} path={`/${c}`}>
            <Suspense fallback={<Loading full={true} />}>
              <Color color={c} tokens={tokens} accountAddress={accountAddress} />
            </Suspense>
          </Route>
          )}

          <Route path="/">
            <Suspense fallback={<Loading full={true} />}>
              <Home tokens={tokens} colors={colors} />
            </Suspense>
          </Route>
        </Switch>

        <div onClick={() => {
          if ('clipboard' in navigator) {
            navigator.clipboard.writeText("0x00c46281dca83b2f5e1dae1819102bf6eb75679b")
          } else {
            return document.execCommand('copy', true, "0x00c46281dca83b2f5e1dae1819102bf6eb75679b")
          }
        }} className="select-none text-white">0x00c46281dca83b2f5e1dae1819102bf6eb75679b<FaCopy className="inline ml-2" /></div>

      </div>
    </Router>
  )
}

export default App